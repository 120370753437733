@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);
@import url(https://fonts.cdnfonts.com/css/monument-extended?styles=28544);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.snowfallContainer
{
  position: fixed;
  height: 100vh;
  width: 99vw;
  z-index: -1;
}
.header{
    font-family: 'Monument Extended', sans-serif;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 20px;
}
.logoContainer
{
    margin-right: 40px;
    border-radius: 60px;
}
.logo{
    height: 140px;
    border-radius: 70px;
    /* box-shadow: -4px -4px 20px 0px  rgba(255,249,34),
                4px 4px 20px 0px rgba(255,0,128); */
                
}

.header h1{
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 30px
}

.header h3
{
    margin-bottom: 30px;
}
.underline{
    margin: auto;
    height: 4px;
    width: 350px;
    background: linear-gradient(0.25turn, rgba(255,249,34),  rgba(255,0,128) );;
}


span.pressG{

    margin-top: 20px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    font-size: 1rem;
    display: block;
}

code{
    padding: 3px 6px;
    background-color: rgba(255, 255, 255, 0.283);
    border-radius: 3px;
    /* backdrop-filter: blur(2px); */
    color: white;
}

@media (max-width:420px){

    .header h1 {
        font-size: 2.5rem;
    }

    .underline {
        width: 280px;
    }
    
}
  #preloader {
  background-color: rgb(36, 36, 36);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #9370DB;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #BA55D3;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #FF00FF;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
html{
    scroll-behavior: smooth;
}

body{
    background-color: rgb(36, 36, 36);
    color: white;
    /* padding: 10px 20px; */  
    max-height: 100vh;
     
}

a{    
    color: white;
}

/* .header{
    font-family: 'Monument Extended', sans-serif;
    text-align: center;
}

.header h1{
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 0;
}

.underline{
    margin: auto;
    height: 4px;
    width: 350px;
    background: linear-gradient(0.25turn, rgba(255,249,34),  rgba(255,0,128) );;
} */

.header h3{
    font-weight: 300;
}

.construction{
    margin: 80px auto 50px;
    padding: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
}


.construction img{
    width: 35vw;
    min-width: 480px;
    padding: 20px;
    -webkit-animation: bounce 3s ease 0s infinite normal none;
            animation: bounce 3s ease 0s infinite normal none;
}



.construction p{
    font-family: 'Monument Extended', sans-serif;
    font-weight: lighter;
    word-spacing: 10%;
    letter-spacing: 1px;
    padding: 20px;
    font-size: 1.5rem;
    width: 30%;
    text-align: left;
}

.construction p span{
    font-size: 0.9rem;
}


@media (max-width:830px) {
    .construction{
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-bottom: 10px;
        margin-top: 50px;
    }

    .construction p{
        width: 70vw;
        text-align: center;
    }

    .construction img {
        width: 90%;
        padding: 0;
        min-width: 0;
        max-width: 520px;
    }

    .pressG{
        visibility: hidden;
    }
    
}

@media (max-width:512px){

    .construction p{
        font-size: 1.2rem;
    }

    .construction p span{
        font-size: 0.8rem;
    }

}

/* @media (max-width:420px){

    .header h1 {
        font-size: 2.5rem;
    }

    .underline {
        width: 280px;
    }
    
} */

/* Animations */

@-webkit-keyframes bounce {
    
    0%{
        -webkit-transform: translate(0,-10px);
                transform: translate(0,-10px);
    }
    50%{
        -webkit-transform: translate(0,10px);
                transform: translate(0,10px);
    }
    100%{
        -webkit-transform: translate(0,-10px);
                transform: translate(0,-10px);
    }
}

@keyframes bounce {
    
    0%{
        -webkit-transform: translate(0,-10px);
                transform: translate(0,-10px);
    }
    50%{
        -webkit-transform: translate(0,10px);
                transform: translate(0,10px);
    }
    100%{
        -webkit-transform: translate(0,-10px);
                transform: translate(0,-10px);
    }
}
.github2{
    position: -webkit-sticky;
    position: sticky;
    bottom: 30px;
    right: 30px;
    text-align: right;
}

.github2 img{
    position: relative;
    right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    /* animation: bounce-2 1s ease 0s infinite; */
    transition: 0.3s;
} 

.github2 img:hover
{
    box-shadow: -2px -2px 10px 0px  rgba(255,249,34),
                2px 2px 10px 0px rgba(255,0,128);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: 0.3s;
}

@media (max-width:830px) {

    .github2
    {
        position: relative;
        bottom: 20px;
        right: 0px;
        text-align: center;
    }
    .github2 img{
        position: relative;
        right: 0px;
        bottom: 20px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: transparent;
        /* animation: bounce-2 1s ease 0s infinite; */
        transition: 0.3s;
    } 
}
#projectHeading{
    text-align: center;
    margin: 80px auto 0px;
    font-family: 'Monument Extended', sans-serif;
    font-size: 2rem;
    -webkit-text-decoration: underline 3px rgb(167, 165, 165);
            text-decoration: underline 3px rgb(167, 165, 165);
}

.oneProject{
    margin: 40px auto;
    padding: 30px 30px;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 60vw;
    display: -webkit-flex;
    display: flex;
}

.number{
    border-radius: 30px;

    background: #262626;
    box-shadow: -24px 24px 54px #1a1a1a,
                24px -24px 54px #323232;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 22vh;
    min-width: 22vh;
    width: 22vh;
    font-family: 'Monument Extended', sans-serif;
    font-size: 1.8rem;
    position: relative;
}

.project-content-right{
    margin-left: 100px;
    text-align: left;
    width: 100%;
}

.project-content-left{
    text-align: right;
    margin-right: 100px;
    width: 100%;
}

.project-content-right h3, .project-content-left h3{
    font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    padding: 0px 30px;
    background-color: white;
    color: rgb(36, 36, 36);
    
}

.project-content-right p, .project-content-left p{
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    font-weight: 300;
    position: relative;
    
}
.languageList
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.language
{
    margin: 10px 10px 15px;
    margin-top: 15px !important;
    padding: 2px 10px;
    background-color: rgba(95, 253, 253, 0.459);
    border: 1px solid rgb(95, 253, 253);
    border-radius: 7px;
    display: -webkit-flex;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    
}

.project-content-left .languageList
{
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    margin-right: -10px;
}

.project-content-right .languageList
{
    margin-left: -10px;

}

.url
{
    padding: 10px 0;
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}
.project-content-left .url
{
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.url a
{
    font-size: 1rem;
    text-decoration: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 15px;
    
}
.url a:hover
{
    text-decoration: underline;
   
}

.icon
{
    margin: 0 8px;
}

.project-img
{
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.topics
{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.topics-right
{
   margin-right: 0;
   margin-left: auto;
   
}

.topic
{
    font-size: 0.7rem;
    font-weight: 400;
    margin: 5px 5px;
    padding: 5px 10px;
    border-radius: 1rem;
    transition: 0.3s;

    /* background-color: rgba(225, 0, 255, 0.287);
    border: 1px solid rgb(212, 148, 248); */

    background-color: rgba(255, 0, 0, 0.301);
    border: 1px solid red;
}

.topic:hover
{
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    transition: 0.3s;
    cursor: pointer;
    
}
