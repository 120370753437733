.header{
    font-family: 'Monument Extended', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.logoContainer
{
    margin-right: 40px;
    border-radius: 60px;
}
.logo{
    height: 140px;
    border-radius: 70px;
    /* box-shadow: -4px -4px 20px 0px  rgba(255,249,34),
                4px 4px 20px 0px rgba(255,0,128); */
                
}

.header h1{
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 30px
}

.header h3
{
    margin-bottom: 30px;
}
.underline{
    margin: auto;
    height: 4px;
    width: 350px;
    background: linear-gradient(0.25turn, rgba(255,249,34),  rgba(255,0,128) );;
}


span.pressG{

    margin-top: 20px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    font-size: 1rem;
    display: block;
}

code{
    padding: 3px 6px;
    background-color: rgba(255, 255, 255, 0.283);
    border-radius: 3px;
    /* backdrop-filter: blur(2px); */
    color: white;
}

@media (max-width:420px){

    .header h1 {
        font-size: 2.5rem;
    }

    .underline {
        width: 280px;
    }
    
}